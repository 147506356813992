import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Smarthome/Homekit/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import YoutubeCard from 'components/Web_User_Interface/1440p_Series/Smarthome/Homekit/YoutubeVideoCard';
import YoutubeCardAppleTv from 'components/Web_User_Interface/1440p_Series/Smarthome/Homekit/YoutubeVideoCardAppleTv';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Smarthome Menu // INSTAR Homekit",
  "path": "/Web_User_Interface/1440p_Series/Smarthome/Homekit/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Use your INSTAR Full HD IP Camera in Apple Homekit. HomeKit is a software framework by Apple, made available in iOS/iPadOS that lets you communicate with, and control your camera using Apple devices. It provides you with a way to automatically discover your camera and access it`s live video.",
  "image": "./WebUI_1440p_SearchThumb_Smarthome_Homekit.webp",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Smarthome_Homekit.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Homebridge_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Smarthome Menu // INSTAR Homekit' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use your INSTAR Full HD IP Camera in Apple Homekit. HomeKit is a software framework by Apple, made available in iOS/iPadOS that lets you communicate with, and control your camera using Apple devices. It provides you with a way to automatically discover your camera and access it`s live video.' image='/images/Search/WebUI_1440p_SearchThumb_Smarthome_Homekit.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Smarthome_Homekit.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Smarthome/Homekit/' locationFR='/fr/Web_User_Interface/1440p_Series/Smarthome/Homekit/' crumbLabel="Homekit" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menü",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-men%C3%BC",
        "aria-label": "smarthome menü permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menü`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cbf035dfbf903bd07802f50c790674a2/8dd93/1440p_Settings_Smarthome_Homekit.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC+0lEQVQ4y22Tu08bdwDH2eOoj8RVC/adXxj7bDdYDnaCrQQM8QF+nF/3sO9soA8qlJQMVaK2Srt0KJWqZmmHekIdOjAg8W8gMTAwAAsssLGwgj7VnetAm5z00Ve3fPTR7343FI/HicViDpIkEYlECAaDTFmvKL34i5lnPaae9pi+gf2ef/onje//JplXET0fMxoeoyDLDMUk6Y0sGo06uO/eZabxJd0Xr9G//hl97ReM5/9jbZ3Fl69J5WTeu+3izod3CPpEhqQbQrvO3tuuW0xmUrTUKs1qiYZSpFqU+5RklGKBojxDef4JqfE4oyEfYyEPIx+5roV22UDocrnI5rJYnS6abtBdXGJpadnZxaVlWm2Tpqqh6S2qpWmWzVlm82lEj/u/wmg0QiQSZeQTN6WFOdrtNpZlcXR0xMXFBefn51xdXbG5uYmiKLTNDu3GHFZxnMKjJCU5++7CsYCHBbnwRnh6eor9XF5eOru9vU2lUqFtWmhKgXpeojyTZLn1+F2FEWLhEPVKEcNo0e122d/fZ29vj52dHafWLqwqCq22hV4tUEiLaMUkP3by18KBdGwsQjo1jq6pqKrGysqKI9B1HU3TWF1dpdfrUa/XMc0OT6YekIm6+W5FZv2LIkNO0Y3CcERiUhqmPvcIvWU6hYeHhxwcHDiVJycnbG1toVQqmFYXeTpDNunm9x9qvGxm+0L7cvfP0JaGGQ7Mky98jmVqmKbJ7u4uZ2dnHB8fOx9mY2ODalVxCmvzDynPBlizclQehPrCwYW2hVJ0lMnGTxS15zSbNRqqjmEYznkaho6ha2iaSkNVUY0Whek0uYkgoaCXD96/1RcOGIgFn0hHeczmqzK/PitSrdWoVkrUzK+offMHnc++5Td5gfVyjfTEBKIoEAz48dl/ykB0LYzg8wdISFFyEwkyyTiJRKLPvSSfph5yb/w+mVicdCJBeDSMKIr4/X6Ht4T2BgIBBEFk2CPg8YoIgvAvXgTvCILXg0cU8QgCPp/PEdlr8w9tMhKDuA2KCwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbf035dfbf903bd07802f50c790674a2/e4706/1440p_Settings_Smarthome_Homekit.avif 230w", "/en/static/cbf035dfbf903bd07802f50c790674a2/d1af7/1440p_Settings_Smarthome_Homekit.avif 460w", "/en/static/cbf035dfbf903bd07802f50c790674a2/7f308/1440p_Settings_Smarthome_Homekit.avif 920w", "/en/static/cbf035dfbf903bd07802f50c790674a2/19805/1440p_Settings_Smarthome_Homekit.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cbf035dfbf903bd07802f50c790674a2/a0b58/1440p_Settings_Smarthome_Homekit.webp 230w", "/en/static/cbf035dfbf903bd07802f50c790674a2/bc10c/1440p_Settings_Smarthome_Homekit.webp 460w", "/en/static/cbf035dfbf903bd07802f50c790674a2/966d8/1440p_Settings_Smarthome_Homekit.webp 920w", "/en/static/cbf035dfbf903bd07802f50c790674a2/61eb3/1440p_Settings_Smarthome_Homekit.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbf035dfbf903bd07802f50c790674a2/81c8e/1440p_Settings_Smarthome_Homekit.png 230w", "/en/static/cbf035dfbf903bd07802f50c790674a2/08a84/1440p_Settings_Smarthome_Homekit.png 460w", "/en/static/cbf035dfbf903bd07802f50c790674a2/c0255/1440p_Settings_Smarthome_Homekit.png 920w", "/en/static/cbf035dfbf903bd07802f50c790674a2/8dd93/1440p_Settings_Smarthome_Homekit.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cbf035dfbf903bd07802f50c790674a2/c0255/1440p_Settings_Smarthome_Homekit.png",
              "alt": "Web User Interface - 1440p Series - Smarthome Homekit",
              "title": "Web User Interface - 1440p Series - Smarthome Homekit",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enable the Homekit service and open the Homekit app on your iOS device and either scan the QR code here in the WebUI of your cameras or use the automatic network scan by selecting that you do not have a QR code to scan. Scanning the QR code will automatically add your camera. If you used the network scan, you need to select the camera you want to add from the list of devices detected on your network:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e654f29c1770359c3d58b6289fdceda9/8dd93/Homekit_iOS_App_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADt0lEQVQ4yzWRfUzUdRzHPwfXYsM1ZWtK/uEf2WabrYVL3bBSOXkSBhfaEp2piYk8GCoS8TBFkqfERM1QEdAY+FAkJESXk6mJyl9Z6SEHyu84hOPud3AcjwNe7X6u9/bed/t8Pt/X3p/vV/yv38XH9BAxtSMt99H9chv5+U+i600sMH6CRMaii45Dt96IhEYzJ3ojoRk56CJitZ546+ExyLpoxBCNLLpyi4W/P2DBzXYC/2jHzwu8ZCKqrolF3uHVYYghEgmJQIJD0K1dT8SBLAIijfhFxuIfZeRVLzwkCt2aSGTejybmN95jwY02Fjbfx6/+NlLVQvjFBkITU1mVeoC1+zJYsy+D5Sn7WZaQogH9Q2PwC4thTkQsft6EIVHIh+HIGxUNzD1Vi5xtQCqa8KlqRsrq+ay+FaYm6Oju5l+zmcdmM0/MZqanp8kur+SVlavxWbXuJeSj8JdncCjybm4Zy/bnoi+5iBRdRp9fg2RXsuunW8As1l4bXZZOOp52YrF04VXRhUu8vjSIue8Ho/8gHP0Kg2bfFQZkTkYZgTmn8CmtQ0qvos+/hKSdZF9TG43XrnC55gK27n8wm/8mMSmRc+fKKaquRQIXI0uCkKUrkSXLkcVByFtBiGSfRzLPIF+fQfKr8S2sRdLKOPhbG1XlP3CsOI/a6nLutd4gPj6O/Pw8qn5tYXPWEZIKj7O7oJTdhcdJKS4j/qtDiAbKPI18WYLs/RbfzO+RtBOktzygprKCk8W5PLzTzKO2m2zbsoGs3Bw6rTZt9YlRD8qzbpz2Ae15vBLZX4ocPIGkf4ckF+KbXIDszCPpSgvPnjymve0OL2w92JTnPLh3l06LBfNzBXNHB42NjZhMJm61ttLU3IzL5UJkz1EktRhJO4bsLcE36SgSn0HC+avaL9teDKAoVpyqyvjEBNPTM/xlfsqwe4Tu7mf0KAqKotDb28vMzAyi25GNJBxCdh9B9nyDPqUA2ZLJzrN12gpj4+O43W5UVWVqaorJyUkedXSiWG3Y7SrPFRsezzj/SyQ+HdmaiWzPRj7PRffFYS3hroprjKoOeqxW+vr6tAR2ux3P6KiW0OlUUdUhHA4nY2MvgbOzs8h84ybmfbyN1zYm4h+3i4BNyUjUDlJPV2lDDucQQ0NuhodHcLncjHhGeWLpwuMZw+Ua1npeOxwqMzOzyNsGA28aInhvw1aWf7qdd4ybCQgOI/lwAcOuIQYdqmbvBac6RP+Anes3mujvt2MfdDJgd2geHHTSY+3lPwHK6ma/I6YYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e654f29c1770359c3d58b6289fdceda9/e4706/Homekit_iOS_App_01.avif 230w", "/en/static/e654f29c1770359c3d58b6289fdceda9/d1af7/Homekit_iOS_App_01.avif 460w", "/en/static/e654f29c1770359c3d58b6289fdceda9/7f308/Homekit_iOS_App_01.avif 920w", "/en/static/e654f29c1770359c3d58b6289fdceda9/19805/Homekit_iOS_App_01.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e654f29c1770359c3d58b6289fdceda9/a0b58/Homekit_iOS_App_01.webp 230w", "/en/static/e654f29c1770359c3d58b6289fdceda9/bc10c/Homekit_iOS_App_01.webp 460w", "/en/static/e654f29c1770359c3d58b6289fdceda9/966d8/Homekit_iOS_App_01.webp 920w", "/en/static/e654f29c1770359c3d58b6289fdceda9/61eb3/Homekit_iOS_App_01.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e654f29c1770359c3d58b6289fdceda9/81c8e/Homekit_iOS_App_01.png 230w", "/en/static/e654f29c1770359c3d58b6289fdceda9/08a84/Homekit_iOS_App_01.png 460w", "/en/static/e654f29c1770359c3d58b6289fdceda9/c0255/Homekit_iOS_App_01.png 920w", "/en/static/e654f29c1770359c3d58b6289fdceda9/8dd93/Homekit_iOS_App_01.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e654f29c1770359c3d58b6289fdceda9/c0255/Homekit_iOS_App_01.png",
              "alt": "Web User Interface - 1440p Series - Smarthome Homekit",
              "title": "Web User Interface - 1440p Series - Smarthome Homekit",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you have not used the QR scanner, you will now be prompted to enter your camera's pin code, which is displayed above the QR code within the webUI. You can then assign a location and name to your camera and save the configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/85717ec5019da68e249bc39ae9eae27f/8dd93/Homekit_iOS_App_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADf0lEQVQ4y0WSWW9bVRSFD1DFSRoqQAUh+AcMasYmMUkzO47jECdtIRKoqFKfQLxAJEQR4k8gnnnmAUEJCIjUppk8xI6bwYnT0Eyeb66vfX2vr+14+JBtJLa0tM/ae699lo6OMNlmeWXuDlc/ustrH9/l1Q/vcGncge3r73h97hOExY6YnEFMOBCjdpomb2L56luetzkQVVR71mnE2BRidAqxvLVDMp3GvxvA5fNxEo4Qk5P8+MffXLXfosE6zWX7LM1V4cgkTdYZJua/ocXqoNHmoMU+g6l62Yid54ZsiKo4EgrhdrlYWX7M/t4ehq7xl8fHW9O3edk6TXN1aU00ickyje3L+7ztuE3z+Pu1emPV4YgdccOKOInFqUYwGMTpdKFpeo0/WHHSdXOOltH/BgcmEP3jmIanmPziPl2zH9Qd1XrWen7PgjiJxGoLnh0d8fRgn7yh1fjC8hoNZgvCPFYf7BpBdAzS1DeB5dN5RPcIos+C6B1DdA3X0TmMeHYWJp1KIctJDC2FkZEplcr8trzGm+YhrrR2c6W9l8utPYhrfZh6Rpn4bJ43rvfzYlsPL3WYaWwzI9oHEO036g5LpRIZLUtG09G0bI3/urRKy/VBmtvMtHT009w9xAvdozSbxxi89zmmVjMN73ZjutbLpc4BROcQ4p0eRDguUS6CoenoqkohV6BYLLPm3+bhhp/HG5s8dHl45Pay6nvC6uYWP/z0M4seH4tOD4tOF3+urLPk9fNgaRXxyOMjraokosecR4+RpShnsQSL6+7aW6qpFF6PB4/LRVpRarXD0xCaquJ2ruPfDuJyb7Lp3QAqiKNQlKgkE0rIHIeiRKr5LMTu4RGGkUNKSOwGAuzu7hKLxdCzBv79A3RdJ1coUTheIBdeRc8VyRfyiIyeRVUzqJrOuZykyhUlRTiWIKmrRM4ThEMxJCmJrudRMzpP9oOUq1bLeS7W71EJfk89KohoQiYal0hndJKKQjKd4TQc5Z/TEOlMhmgsQSQcR5IUNC1HWtXx7wW5uChSKZdIyTFyukqlUqFcLiM2toN4toKchOPEJZnA4Qkr3h38gQOKxRKFQolUKoOiqLVzPn/B3tNDjFyBnFFAkVOklTRGNk+lAmJzJ0gV3u3/4Q8csub2EY5E0XSDc1lBOk/WvlZCkvll4XdSqko+m8FIxzGUCPlclkgszr+x1TnbEJxgygAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85717ec5019da68e249bc39ae9eae27f/e4706/Homekit_iOS_App_02.avif 230w", "/en/static/85717ec5019da68e249bc39ae9eae27f/d1af7/Homekit_iOS_App_02.avif 460w", "/en/static/85717ec5019da68e249bc39ae9eae27f/7f308/Homekit_iOS_App_02.avif 920w", "/en/static/85717ec5019da68e249bc39ae9eae27f/19805/Homekit_iOS_App_02.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/85717ec5019da68e249bc39ae9eae27f/a0b58/Homekit_iOS_App_02.webp 230w", "/en/static/85717ec5019da68e249bc39ae9eae27f/bc10c/Homekit_iOS_App_02.webp 460w", "/en/static/85717ec5019da68e249bc39ae9eae27f/966d8/Homekit_iOS_App_02.webp 920w", "/en/static/85717ec5019da68e249bc39ae9eae27f/61eb3/Homekit_iOS_App_02.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/85717ec5019da68e249bc39ae9eae27f/81c8e/Homekit_iOS_App_02.png 230w", "/en/static/85717ec5019da68e249bc39ae9eae27f/08a84/Homekit_iOS_App_02.png 460w", "/en/static/85717ec5019da68e249bc39ae9eae27f/c0255/Homekit_iOS_App_02.png 920w", "/en/static/85717ec5019da68e249bc39ae9eae27f/8dd93/Homekit_iOS_App_02.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/85717ec5019da68e249bc39ae9eae27f/c0255/Homekit_iOS_App_02.png",
              "alt": "Web User Interface - 1440p Series - Smarthome Homekit",
              "title": "Web User Interface - 1440p Series - Smarthome Homekit",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "activating-the-notification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#activating-the-notification",
        "aria-label": "activating the notification permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Activating the notification`}</h3>
    <p>{`To receive a notification on your iPhone or iPad when an alarm is triggered, you need to activate the notification for your camera in the Homekit app:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/10fb2be5e5171c50e3b228a296c5c256/0d6fe/Homekit_1080p_Smartphone_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVQY0wGrAVT+AOPn6pyosb7Bw83Oyt/c0ejf0vr287nCyqWutL7AvcDCuuPbzfTr4+Ll6Z2qsrq9vbu8t9jTx+ng0/r28wDT2NxfcYEuOkYAAABlX1v/79788+2PnqlMXGt2fYaeqKzl3M7/8uTS1tlUZ3dibXqQk5nLta/76tn99e8A2N7il5ObmoiOXnOLVF9s0q2p///3pKy2pJCV5eDh////2LW4/+LY2ODikYqUxbK1+f3+8ezv5r+7//vzANrd4LCbneDCvk1ZaAwbGnRcWv/9+K2ss9SwrMa6vPLj5O3M0PDb19fZ3LSdode5ttLR2fv9/+zS0/fu6QD79vDEtLL15uVtXVoAAAAZIi3T2N/g083TxMTk1NPMz9Wzw8qYobHx7OnJuLfr3NnPxc3O2OGZo7DN0doA+Pb0rKSnsJ6g2c7HYWFodoCMsb/N497bk4SK38/KlpWbZmlvfo+h6OrrrqansZ+g08zKaGp0aXSAt8TTAPn5+dHO0birr/Pl4f/17/Hs6dfg5+/u77evtNXGxf3w6v/17tnf4+zv8tLP0bisr/Xn4f/07fDr6dri6HBZNoQXdgcdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/10fb2be5e5171c50e3b228a296c5c256/e4706/Homekit_1080p_Smartphone_03.avif 230w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/d1af7/Homekit_1080p_Smartphone_03.avif 460w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/7f308/Homekit_1080p_Smartphone_03.avif 920w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/eec0b/Homekit_1080p_Smartphone_03.avif 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/10fb2be5e5171c50e3b228a296c5c256/a0b58/Homekit_1080p_Smartphone_03.webp 230w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/bc10c/Homekit_1080p_Smartphone_03.webp 460w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/966d8/Homekit_1080p_Smartphone_03.webp 920w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/177a2/Homekit_1080p_Smartphone_03.webp 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/10fb2be5e5171c50e3b228a296c5c256/81c8e/Homekit_1080p_Smartphone_03.png 230w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/08a84/Homekit_1080p_Smartphone_03.png 460w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/c0255/Homekit_1080p_Smartphone_03.png 920w", "/en/static/10fb2be5e5171c50e3b228a296c5c256/0d6fe/Homekit_1080p_Smartphone_03.png 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/10fb2be5e5171c50e3b228a296c5c256/c0255/Homekit_1080p_Smartphone_03.png",
              "alt": "Web User Interface - 1440p Series - Smarthome Homekit",
              "title": "Web User Interface - 1440p Series - Smarthome Homekit",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Press the cogwheel icon in the camera view to enter the settings.`}</li>
      <li parentName="ol">{`There, select `}<strong parentName="li">{`notifications`}</strong>{`.`}</li>
      <li parentName="ol">{`Then activate all notifications. When an alarm occurs, you will receive a notification that takes you directly to the live video of the camera.`}</li>
    </ol>
    <h2 {...{
      "id": "smarthome-unterstützung-mit-apple-homekit-und-secure-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-unterst%C3%BCtzung-mit-apple-homekit-und-secure-video",
        "aria-label": "smarthome unterstützung mit apple homekit und secure video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Unterstützung mit Apple Homekit und Secure Video`}</h2>
    <p>{`Use your INSTAR 2K+ IP Camera in Apple HomeKit. HomeKit is a software framework by Apple, made available in iOS/iPadOS that lets you communicate with, and control your camera using Apple devices. It provides you with a way to automatically discover your camera and access it\\'s live video.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeCard mdxType="YoutubeCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Enable the HomeKit service and open the HomeKit app on your iOS device and either scan the QR code here in the Web UI of your cameras or use the automatic network scan by selecting that you do not have a QR code to scan. Scanning the QR code will automatically add your camera. If you used the network scan, you need to select the camera you want to add from the list of devices detected on your network.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Video Recording`}</strong>{`: Automatic camera streaming/recording mode based on the presence of added HomeKit users.`}</li>
      <li parentName="ul"><strong parentName="li">{`Object and face recognition`}</strong>{`: Use built-in object and face recognition on the HomePod or Apple TV to view events in a recording timeline.`}</li>
      <li parentName="ul"><strong parentName="li">{`Apple iCloud`}</strong>{`: Direct recording to Apple iCloud account. Recordings are stored in Apple iCloud for up to 10 days without affecting the original storage schedule.`}</li>
      <li parentName="ul"><strong parentName="li">{`Automatisierung`}</strong>{`: Linking HomeKit automations to camera events.`}</li>
      <li parentName="ul"><strong parentName="li">{`Status-LED`}</strong>{`: LED-Protokoll zur Anzeige des aktuellen Status. `}<strong parentName="li">{`Red LED`}</strong>{` bei Live-Stream-Zugriff oder aktivierter Aufnahme. `}<strong parentName="li">{`Blue LED`}</strong>{` when stream is enabled without current access.`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeCardAppleTv mdxType="YoutubeCardAppleTv" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "status-led",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-led",
        "aria-label": "status led permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status LED`}</h3>
    <p>{`The possible combinations from this follow:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Red/Blue`}</strong>{`: For potential recording without current access.`}</li>
      <li parentName="ul"><strong parentName="li">{`Only Red`}</strong>{`: With current live stream access or recording.`}</li>
      <li parentName="ul"><strong parentName="li">{`No LEDs`}</strong>{`: Currently no live stream available.`}</li>
    </ul>
    <h3 {...{
      "id": "storage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#storage",
        "aria-label": "storage permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Storage`}</h3>
    <p>{`Different iCloud+ storage plans for the number of supported cameras:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`1 Camera`}</strong>{`: 50GB iCloud+`}</li>
      <li parentName="ul"><strong parentName="li">{`5 Camera`}</strong>{`: 200GB iCloud+`}</li>
      <li parentName="ul"><strong parentName="li">{`Unlimited`}</strong>{`: 2TB iCloud+`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      